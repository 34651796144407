import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Observable } from 'rxjs';
import { Response, NEPReportData } from '../interfaces';
import { environment } from '../../../environments/environment';
import { USER_ROLES } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUserRoles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private toastrService: NbToastrService) {
    if (this.adminCredential.token && this.adminCredential.roles) {
      this.login();
    } else {
      this.logout();
    }
  }

  get adminCredential() {
    const adminCredential = sessionStorage.getItem('adminCredential');

    if (adminCredential) {
      try {
        return JSON.parse(adminCredential);
      } catch (error) {
        return { token: null, roles: [] };
      }
    }
    return { token: null, roles: [] };
  }

  set adminCredential(token: { token: string | null; roles: string[] }) {
    if (token) {
      sessionStorage.setItem('adminCredential', JSON.stringify(token));
    } else {
      sessionStorage.removeItem('adminCredential');
    }
  }

  login() {
    this.isLoggedIn$.next(true);
    this.currentUserRoles$.next(this.adminCredential.roles);
  }

  logout() {
    this.adminCredential = { token: null, roles: [] };
    this.isLoggedIn$.next(false);
    this.currentUserRoles$.next([]);
  }

  checkLogin(login: string, password: string) {
    const credential = this.stringToBase64(`${login}:${password}`);
    // const credential = Buffer.from(`admin:d34h82BD!@8nd#7BF20`).toString('base64'); // qa
    // const credential = Buffer.from('admin:P2mj%tkwJrrR5?sL').toString('base64'); // local
    // const credential = Buffer.from( `admin:3@QW#xnKZjMjt$4g` ).toString( 'base64' ); // prod

    const params = { page: '0', size: '1' };

    this.http
      .get<Response<NEPReportData>>(`${environment.gaiminApi}/admin/statistics/devices-new`, {
        headers: new HttpHeaders({
          Authorization: `Basic ${credential}`
        })
      })
      .subscribe(
        (res) => {
          if (res?.success) {
            this.getUserPermissions(credential).subscribe({
              next: (response: Response<USER_ROLES[]>) => {
                if (response.success && response.data) {
                  this.adminCredential = { token: credential, roles: response.data };
                  this.login();
                } else {
                  this.logout();
                }
              },
              error: () => {
                this.logout();
              }
            });
          }
        },
        () => {
          this.logout();
          this.toastrService.danger('Please try again.', 'Sorry, something went wrong!');
        }
      );
  }

  checkUserRights(userRoles: string[]) {
    return userRoles.some((roleToCheck) => this.currentUserRoles$.getValue().includes(roleToCheck));
  }

  stringToBase64(str: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    return btoa(String.fromCharCode(...data));
  }

  private getUserPermissions(credential: string): Observable<Response<USER_ROLES[]>> {
    return this.http.get<Response<USER_ROLES[]>>(`${environment.gaiminApi}/admin/roles`, {
      headers: new HttpHeaders({
        Authorization: `Basic ${credential}`
      })
    });
  }
}
