<div class="header-container">
  <div class="logo-container">
    <a class="logo" href="#" (click)="navigateHome()">
      <img class="logo__img" src="../../../../assets/images/gaimin-logo.svg" />
    </a>
  </div>
</div>
<div class="header-container">
  <button
    *ngIf="authService.isLoggedIn$ | async"
    (click)="adminLogout()"
    nbButton
    status="primary"
    outline
    class="logout">
    Logout
  </button>
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.displayName"
        [picture]="user?.photoURL"
        *ngIf="user">
      </nb-user>
    </nb-action>
  </nb-actions>
  <nb-icon icon="menu-outline" (click)="toggleSidebar()"></nb-icon>
</div>
