import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from './../services/auth.service';
import { environment } from '../../../environments/environment';
import packageData from '../../../../package.json';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastrService: NbToastrService, public authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.includes(environment.gaiminApi)) {
      request = request.clone({
        headers: request.headers
          .set('X-Client-Version', `backoffice\\${packageData.version}`)
          .set('Accept-Language', `en_US`)
      });
    }

    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse && request.url.includes(environment.gaiminApi)) {
          if (!(evt.body instanceof Blob) && !evt?.body.success) {
            this.toastrService.danger(
              `Error: ${evt?.body.error.type}`,
              `Something went wrong! ${evt?.body.error.description}`
            );
            if (evt?.body.error.type == 'AUTHENTICATION') {
              this.authService.logout();
            }
          }
        }
      }),
      catchError((error): any => {
        console.log('Error:', error);
        this.toastrService.danger(`Error: ${error?.error.error}`, `Something went wrong!`);
      })
    );
  }
}
