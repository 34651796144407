import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'ngx-login',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  public authInfo: Observable<firebase.User> | undefined;

  constructor(public auth: AngularFireAuth, private router: Router) {}

  login() {
    this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then((user) => {
      if (user?.user?.email?.includes('theraven.tech') || user?.user?.email?.includes('gaimin.io')) {
        this.router.navigateByUrl('/pages/dashboard');
      } else {
        this.auth.signOut().then();
      }
    });
  }
}
